
import { defineComponent } from "vue";

export default defineComponent({
  name: "KtWidget7",
  components: {},
  props: {
    widgetClasses: {type: String, default: ""},
  },
  setup() {
    const items = [
      [
        {
          icon: {
            file: "media/icons/duotune/coding/cod002.svg",
            color: "success",
          },
          info: {
            title: "Media Device",
            description: "Voice and video recorder",
          },
          date: "5 day ago",
          badge: {
            label: "Delivered",
            color: "success",
          },
        },
        {
          icon: {
            file: "media/icons/duotune/general/gen024.svg",
            color: "danger",
          },
          info: {
            title: "Special Meal",
            description: "Quona Rice",
          },
          date: "2 day ago",
          badge: {
            label: "Delivered",
            color: "danger",
          },
        },
        {
          icon: {
            file: "media/icons/duotune/maps/map004.svg",
            color: "primary",
          },
          info: {
            title: "New Users",
            description: "Awesome Users",
          },
          date: "4 day ago",
          badge: {
            label: "Delivered",
            color: "primary",
          },
        },
        {
          icon: {
            file: "media/icons/duotune/abstract/abs027.svg",
            color: "warning",
          },
          info: {
            title: "Active Customers",
            description: "Best Customers",
          },
          date: "1 day ago",
          badge: {
            label: "Delivered",
            color: "warning",
          },
        },
        {
          icon: {
            file: "media/icons/duotune/art/art007.svg",
            color: "info",
          },
          info: {
            title: "Strawberry Boxes",
            description: "From Spain",
          },
          date: "7 day ago",
          badge: {
            label: "Delivered",
            color: "info",
          },
        },
      ],

      [
        {
          icon: {
            file: "media/icons/duotune/abstract/abs042.svg",
            color: "info",
          },
          info: {
            title: "Strawberry Boxes",
            description: "From Spain",
          },
          date: "4 week ago",
          badge: {
            label: "Delivered",
            color: "info",
          },
        },
        {
          icon: {
            file: "media/icons/duotune/general/gen024.svg",
            color: "danger",
          },
          info: {
            title: "Special Meal",
            description: "Quona Rice",
          },
          date: "2 week ago",
          badge: {
            label: "Delivered",
            color: "danger",
          },
        },
        {
          icon: {
            file: "media/icons/duotune/abstract/abs027.svg",
            color: "warning",
          },
          info: {
            title: "Active Customers",
            description: "Best Customers",
          },
          date: "5 week ago",
          badge: {
            label: "Delivered",
            color: "warning",
          },
        },
      ],

      [
        {
          icon: {
            file: "media/icons/duotune/ecommerce/ecm002.svg",
            color: "success",
          },
          info: {
            title: "Media Device",
            description: "Voice and video recorder",
          },
          date: "3 month ago",
          badge: {
            label: "Delivered",
            color: "success",
          },
        },
        {
          icon: {
            file: "media/icons/duotune/general/gen024.svg",
            color: "danger",
          },
          info: {
            title: "Special Meal",
            description: "Quona Rice",
          },
          date: "5 month ago",
          badge: {
            label: "Delivered",
            color: "danger",
          },
        },
        {
          icon: {
            file: "media/icons/duotune/abstract/abs027.svg",
            color: "warning",
          },
          info: {
            title: "Active Customers",
            description: "Best Customers",
          },
          date: "6 month ago",
          badge: {
            label: "Delivered",
            color: "warning",
          },
        },
        {
          icon: {
            file: "media/icons/duotune/abstract/abs042.svg",
            color: "info",
          },
          info: {
            title: "Strawberry Boxes",
            description: "From Spain",
          },
          date: "4 month ago",
          badge: {
            label: "Delivered",
            color: "info",
          },
        },
      ],
    ];

    return {
      items,
    };
  },
});
